
/*---------------- KEYBOARD ---------------*/

.banner {
    max-width: 1200px;
    max-height: 340px;
    width: 100%;
    height: auto;
    object-fit: cover;
    object-position: 0px -90px;
}

.title {
    color: #F50890;
    font-size: 40px;
    text-shadow: 0px -1px 5px #F5F408;
}

.content {
    background-color: #FAD7A0;
    padding: 30px;
    border-style: double;
    border-width: 5px;
    font-size: 18px;
    text-align: left;
    max-width: 1200px;
    width: 100%;
    margin: 20px auto;
}

.key {
    position: absolute;
    font-family: Helvetica;
    font-weight: 100;
    font-size: 12px;
    border: 1px solid rgba(32, 32, 32, 0.2);
    border-radius: 0px 0px 5px 5px;
    cursor: pointer;
    box-shadow: 0px 5px 1px rgba(32, 32, 32, 0.2);
    -webkit-transition: margin 0.05s ease, background-color 0.05s ease, box-shadow 0.05s ease;
}

.key:hover {
    background-color: #6e6658;
}

.key .label {
    position: absolute;
    bottom: 15px;
    text-align: center;
    left: 0px;
    right: 0px;
}

.keyLabel {
    font-weight: 900;
    text-shadow: 0px -1px 5px #FAD7A0;
    font-size: 15px;
}

.black {
    background-color: rgb(32, 32, 32);
    color: #ffffff;
    z-index: 1;
    text-shadow: 0px -1px 1px #FF0000;
}

.white {
    background-color: #F2F2DE;
    color: #112d32;
    z-index: 0;
    text-shadow: 0 1px 1px #88BDBC;
}

.keyboard-holder {
    margin: 2rem auto;
    height: 200px;
    position: relative;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
}

#kb {
    text-align: center;
}
